import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Indicator from "-!url-loader!ui/elements/List/UnorderedListBulletWhite.svg"

import RenderBlockText from 'widgets/RenderBlockText'
import { BulletList } from 'ui/elements/List/styles'

const Cta = styled.div.attrs(props => ({
  $color: props.$color,
}))`
  display: block;
  padding: 2rem;

  background: ${(props) => props.theme.palette[props.$color]};

  // ensure there is not too much whitespace
  div:first-of-type :first-child {
    margin-top: 0;
  }

  div:last-of-type :last-child {
    margin-bottom: 0;
  }

  ${(props) => {
    switch (props.$color) {
      case 'primary':
      case 'gunmetal':
        return css`
          color: #fff;

          ${BulletList} li:before {
            background-image: url(${Indicator});
          }
        `
      case 'paperDelta':
      default:
        return css``
    }
  }}
`

const BlockCTA = ({ content, color }) => {
  return <Cta $color={color}>
    <RenderBlockText blocks={content} />
  </Cta>
}
BlockCTA.propTypes = {
  content: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
}
export default BlockCTA
