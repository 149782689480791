import styled from 'styled-components';
import Indicator from "-!url-loader!./UnorderedListBulletBlack.svg"

export const BulletList = styled.ul`
  list-style: none;

  li {
    position: relative;
    // page default
    line-height: 28px;

    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: -25px;

      width: 15px;
      height: 15px;

      transform: translate(0, -50%);

      background-image: url(${Indicator});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
    }
  }
`;
